@import './../../../styles/_colors.scss';
@import './../../../styles/_common.scss';

.hidden {
  display: none;
}

.editContainer {
  display: inline-flex;
  align-items: center;
}

.editIcon {
  margin-left: 40px;
  vertical-align: middle;
}

.header {
  display: inline-flex;
}

.headerContainer {
  color: $black;
  margin-bottom: 20px;
  padding-top: 10px;
}