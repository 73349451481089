@import './../../styles/_colors.scss';
@import './../../styles/_common.scss';

.addressContainer {
    align-self: center;
    font-family: $font-stack-all;
    font-size: 14px;
    width: 100%;
}

.buttonAlign {
    align-self: flex-end;
    margin-left: auto;
}

.buttonContainer {
    align-content: flex-end;
    align-items: center;
    border-top: 1px solid $nobel;
    display: flex;
    margin-top: 30px;
    padding-top: 10px;
}

.continueButton {
    cursor: pointer;
    font-size: 14px;
    height: 32px;
    line-height: 1.43;
    width: 125px;

    &:focus::after {
        border-color: $white ;
        border-width: 1.5px;
        border-style: solid;
    }
}

.fieldContainer {
    width: inherit;
}

.headerContainer {
    color: $black;
    margin-bottom: 30px;
}

.message {
    font-size: 11px;
}

.popup {
    max-width: 700px;
    min-height: 350px;
    min-width: 600px;
}

.spinnerStyle {
    align-self: flex-end;
    margin: 4px;
}

.fieldsIcon {
    position: absolute;
    font-size: 1rem;
    color: $tuatara;
    top: 9%;
    left: 39%;
}

.emailfieldsIcon {
    left: 12%;
}

.emailfieldsIconAfterValidation {
    left: 12%;
    top: 7%;
}

.nameFieldsIconAfterValidation {
    top: 7%;
}

.addressFieldsIcon {
    left: 18%;
    top: 10%;
}

.addressFieldsIconAfterValidation {
    left: 18%;
    top: 8%;
}

.secondaddressFieldsIcon {
    left: 17%;
    top: 10%;
}

.secondaddressFieldsIconAfterValidation {
    left: 17%;
    top: 7%;
}

.cityfieldsIcon {
    left: 20%;
    top: 10%;
}

.cityfieldsIconAfterValidation {
    left: 20%;
    top: 7%;
}

.fieldIconsDisabled {
    color: rgb(161, 159, 157);
    pointer-events: none;
}