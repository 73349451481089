.anchorLink {
    text-decoration: none;
    padding: 0px 8px;
    font-size: 18px;
    line-height: 36px;
    color: #727272;

    &:hover {
        background-color: #edebe9;
        color: black;
        background-size: 50px;
        display: inline-block;
        height: max-content;
        width: max-content;
    }
}