@import './../../styles/_colors.scss';

.button {
    background-color: $black;
    border: 0;
    color: $white;
    cursor: pointer;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 32px;
    letter-spacing: normal;
    line-height: 1.43;
    margin-left: 22px;
    width: 120px;    
}

.container {
    display: flex;
    flex-direction: column;
}

.commonFocus{
    &:focus{
        outline-offset: 3px;
    }
}

.disabled {    
    cursor: not-allowed;
    background-color: $gray;
    color: $white;
}

.inputsSection {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
}

.description {
    font-size: 14px;
}

.descriptionContainer {
    margin-top: 30px;
}

.descriptionHeader {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 20px;
    height: 20px;
    font-weight: normal;
}

.errorSection {
    color: $alizarinCrimson;
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
}

.errorIcon {
    margin-right: 5px;
}

.label {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    display: flex;
    font-weight: 600;
}

.faq {
    margin-left: 5px;
    width: 20px;
    height: 20px;
}

.spinnerStyle {
    margin-bottom: 6px;
    margin-left: 10px;
}

@media screen and (-ms-high-contrast: active) {
    .button {
        -ms-high-contrast-adjust: none;
        background-color: $white !important;
        color: $black !important;
    }
}
@media screen and (-ms-high-contrast: black-on-white) {
    .button {
        -ms-high-contrast-adjust: none;
        background-color: $black !important;
        color: $white !important;
    }
}

.shippingMethod {
    padding: 0px 8px 40px 8px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
}
