@import './../../styles/_colors.scss';

.body {
    border-top: solid 1px $nobel;
}

.spinner {
    margin: 20px;
}

.searchContainer {
    display: block;
    width: 100%;
    padding: 0px;
}

.eligibilityToolTip {
    height: 20px;
    margin-left: 5px;
    padding-bottom: 4px;
    vertical-align: middle;
    width: 20px;
}

.warningIcon {
    color: $persianRed;
    font-size: 16px;
    margin-left: 5px;
}

.grid {
    border: 1px solid $gray;
}

.gridParent {
    overflow: hidden;
    overflow-x: auto;
    padding-top: 30px;
}

.tableNonBordered tr {
    padding: 0px 10px;
    display: block;
}

.tableNonBordered,
.tableNonBordered th,
.tableNonBordered td {
    border: none;
}

.tableNonBordered thead {
    background: #fff;
    display: block;
}

.tableNonBordered th {
    vertical-align: top;
    height: 42px;
    line-height: 42px;
    white-space: nowrap;
    box-sizing: content-box;
    text-align: left;
    padding: 0px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-weight: 600;
    font-size: 14px;
    outline: transparent;
    color: rgb(50, 49, 48);
    text-overflow: ellipsis;
    overflow: hidden;
}

.tableNonBordered tbody {
    overflow: hidden;
    display: block;
}

.tableNonBordered tbody tr:nth-child(even) {
    background: rgb(238, 238, 238);
}

.tableNonBordered td {
    color: rgb(96, 94, 92);
    min-height: 32px;
    line-height: 32px;
    white-space: nowrap;
    text-align: left;
    padding: 0px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 400;
    outline: transparent;
    box-sizing: border-box;
}

.tableNonBordered td i {
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    color: rgb(0, 120, 212);
}

.tdNoRecordFound {
    text-align: center !important;
    font-weight: bold !important;
    min-width: 950px;
}

.pagination {
    float: left;
    margin-top: 25px;
    width: 100%;
    text-align: center;
}

.pagination div {
    display: inline-block;
    cursor: pointer;
    color: black;
    text-decoration: none;
    transition: background-color .3s;
    height: 32px;
    width: 32px;
    text-align: center;
    margin: 0px 5px;
    font-size: 14px;
    line-height: 32px;
}

.pagination div button {
    height: 32px;
}

.pagination div.active {
    background-color: rgb(0, 120, 212);
    color: white;
    border: 1px solid rgb(0, 120, 212);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    text-decoration: underline;
    font-weight: bold;
}