@import './../../../styles/_colors.scss';
@import './../../../styles/_common.scss';

.accessorySection{
  border-bottom: 1px solid $nobel;
  padding-bottom: 20px;
  padding-top: 15px;
}

.changeSelection {
  background-color: $white;
  border: 2px solid;
  color: $black;
  cursor: pointer;
}

.continueButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid $nobel;
}

.continueButton {
  align-self: flex-end;
  background-color: $black;
  color: $white;
  font-size: 14px;
  height: 32px;
  line-height: 20px;
  padding: 0 10px;
  width: 125px;

  &:hover {
    background-color: $black;
    color: $white;
  }

  &:disabled {
    background-color: $gray;
    cursor: not-allowed;
  }

  &:focus::after {
    border-color: $white !important;
    border-width: 1.5px;
  }
}

.deleteIcon {
  cursor: pointer;
  font-size: 17px;
  height: 20px;
  width: 20px;
}

.headerSection {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spinner{
  margin-top: 20px;
}

.parentSection{
  padding-bottom: 20px;
}

.serialNumberContainer{
  width: 940px;
  margin-top: 20px;
}

.sectionTitle{
  font-size: 18px;
}

.problemInfoSectionContainer{
  display: table;
  margin-left: 5px;
}

.problemInfoSection{
  display: table-row;
  flex-direction: row;
}

.problemInfoLabel{
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.5);
  display: table-cell;
  padding-top: 5px;
}

.problemInfoSpan{
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  display: table-cell;
  padding-left: 15px;
  padding-top: 5px;
}

.offerTableContainer{
  width: inherit;
}
